import i18n from "../components/i18n";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {
  const langCode = i18n.language;
  const defaultLangCode = "fr";

  try {
    if (typeof window !== "undefined") {
      navigate(`${langCode}/`);
    }
  } catch {
    navigate(`${defaultLangCode}/`);
  }
  return null;
};

export default IndexPage;
